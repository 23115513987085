<template>
  <div>
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <vx-card v-else>
      <form>
        <div class="mb-4 px-4">
          <fieldset class="vx-row rounded-lg pb-3 px-1 pt-1" style="border-color: rgba(255,255,255,0.6)">
            <legend class="mx-3 px-2"><strong>{{ $t('fields.code') }} & {{ $t('fields.priority') }}</strong></legend>
            <div class="vx-col my-2 md:w-1/2 w-full">
              <vs-input v-validate="'required_if:canPublished,true'" class="w-full" name="code" :placeholder="$t('fields.code')" v-model="category.code" />
              <span class="text-danger text-sm" v-show="errors.has('code')">{{ errors.first('code') }}</span>
            </div>
            <div class="vx-col my-2 md:w-1/2 w-full">
              <vs-input v-validate="'required'" type="number" class="w-full" name="priority" :placeholder="$t('fields.priority')" v-model="category.priority" />
              <span class="text-danger text-sm" v-show="errors.has('priority')">{{ errors.first('priority') }}</span>
            </div>
          </fieldset>
        </div>
        <div class="mb-4 px-4">
          <fieldset class="vx-row rounded-lg pb-3 px-1 pt-1" style="border-color: rgba(255,255,255,0.6)">
            <legend class="mx-3 px-2"><strong>{{ $t('fields.title') }}</strong></legend>
            <div class="vx-col my-2 md:w-1/3 w-full">
              <vs-input v-validate="'required'" class="w-full" name="name_tm" placeholder="Turkmen" v-model="category.name_tm" />
              <span class="text-danger text-sm" v-show="errors.has('name_tm')">{{ errors.first('name_tm') }}</span>
            </div>
            <div class="vx-col my-2 md:w-1/3 w-full">
              <vs-input v-validate="'required_if:canPublished,true'" class="w-full" name="name_ru" placeholder="Russian" v-model="category.name_ru" />
              <span class="text-danger text-sm" v-show="errors.has('name_ru')">{{ errors.first('name_ru') }}</span>
            </div>
            <div class="vx-col my-2 md:w-1/3 w-full">
              <vs-input v-validate="'required_if:canPublished,true'" class="w-full" name="name_en" placeholder="English" v-model="category.name_en" />
              <span class="text-danger text-sm" v-show="errors.has('name_en')">{{ errors.first('name_en') }}</span>
            </div>
          </fieldset>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <span><strong>{{ $t('select') }} {{ $t('brands') }}</strong></span>
            <v-select :options="brands" label="name_tm" name="brands" :placeholder="$t('select') + ' ' + $t('brands')" multiple v-model="category.category_brands" />
            <span class="text-danger text-sm" v-show="errors.has('brands')">{{ errors.first('brands') }}</span>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <span><strong>{{ $t('select') }} {{ $t('fields.type') }}</strong></span>
            <v-select v-validate="'required_if:canPublished,true'" :options="types" name="type" :placeholder="$t('selectOne')" v-model="category.type" :label="`name_${$i18n.locale}`" :reduce="opt => opt.name_en" />
            <span class="text-danger text-sm" v-show="errors.has('type')">{{ errors.first('type') }}</span>
          </div>
        </div>
        <div class="mb-4">
          <fieldset class="rounded-lg px-1" style="border-color: rgba(255,255,255,0.6)">
            <legend class="mx-3 px-2"><strong>{{ $t('fields.properties') }}</strong></legend>
            <ul class="vx-row centerx px-2">
              <li class="vx-col my-4 ml-2 mr-4 flex">
                <vs-switch v-model="parent" />
                <label class="ml-3">{{$t('doesHasParentCat')}}?</label>
              </li>
              <li class="vx-col my-4 ml-2 mr-4 flex">
                <vs-switch v-model="category.isLeaf" />
                <label class="ml-3">{{$t('isLeafCat')}}?</label>
              </li>
              <li class="vx-col my-4 ml-2 mr-4 flex">
                <vs-switch name="isActive" ref="isActive" v-model="category.isActive" />
                <label class="ml-3">{{$t('isActive')}}?</label>
              </li>
              <li class="vx-col my-4 ml-2 mr-4 flex">
                <vs-switch v-validate="'required_if:isActive,true'" name="canPublished" ref="canPublished" v-model="category.canPublished" />
                <label class="ml-3" :class="errors.has('canPublished') ? 'text-danger' : ''">{{ $t('canBePublished') }}?</label>
              </li>
              <span class="px-3 text-danger text-sm" v-show="errors.has('canPublished')">{{ errors.first('canPublished') }}</span>
            </ul>
          </fieldset>
        </div>
        <div v-if="parent" class="vx-row mb-3">
          <div class="vx-col w-full">
            <span><strong>{{ $t('select') }} {{ $t('fields.parent') }}</strong></span>
            <v-select v-validate="'required'" :label="`name_${$i18n.locale}`" :options="categories" name="link" placeholder="Select one" v-model="category.parentId" :reduce="cat => cat.id" />
            <span class="text-danger text-sm" v-show="errors.has('link')">{{ errors.first('link') }}</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col upload-img mt-3" v-if="!category.image_isAdded">
            <input name="photo" type="file" class="hidden" ref="uploadImgInput" @change="updateCurrImg" accept="image/*">
            <vs-button @click="$refs.uploadImgInput.click()" type="border" icon-pack="feather" icon="icon-upload">{{ $t('upload') }} {{ $t('image') }}</vs-button>
            <span class="text-danger text-sm" v-show="errors.has('photo')">{{ errors.first('photo') }}</span>
          </div>
        </div>
        <template v-if="category.image_isAdded">
          <!-- Image Container -->
          <div class="img-container w-64 mx-auto flex items-center justify-center">
            <img :src="category.image_isAdded" alt="img" class="responsive">
          </div>

          <!-- Image upload Buttons -->
          <div class="modify-img flex justify-between mt-5">
            <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept="image/*">
            <vs-button class="mr-4" type="flat" @click="$refs.updateImgInput.click()">{{ $t('update') }} {{ $t('image') }}</vs-button>
            <vs-button type="flat" color="#999" @click="category.image_isAdded = null">{{ $t('remove') }} {{ $t('image') }}</vs-button>
          </div>
        </template>
        <div class="vx-row">
          <vs-button type="filled" @click.prevent="submitForm" class="ml-auto mt-5 block">{{$t('submit')}}</vs-button>
          <vs-button type="border" color="warning" @click.prevent="$router.back()" class="mr-auto ml-4 mt-5 block">{{$t('cancel')}}</vs-button>
          <!-- <vs-button type="border" color="danger" @click.prevent="cancelForm" class="ml-auto mr-4 mt-4 mb-2 block">Cancel</vs-button> -->
        </div>
      </form>
    </vx-card>
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: {
    vSelect
  },
  data () {
    return {
      category: {},
      parent: false,
      brands: [],
      categories: [],
      types: [
        {name_en: 'both', name_tm: 'Ikisi hem', name_ru: 'Оба'},
        {name_en: 'market', name_tm: 'Market', name_ru: 'Маркет'},
        {name_en: 'express', name_tm: 'Ekspress', name_ru: 'Экспресс'}
      ],
      imageUpdated: false,
      isLoading: true
    }
  },
  async created () { await this.fetchData() },
  methods: {
    async fetchData () {
      await this.$http.get(`/categories/${this.$route.params.id}`).then(response => {
        this.category = {
          ...response.data,
          image_isAdded: response.data.image_isAdded ? `${this.$baseUrl}/categories/${response.data.uuid}.webp` : null
        }
        this.parent = !!this.category.parentId
        this.isLoading = false
      }).catch(err => { this.notify('Error', this.$t('notify.error'), err.message) })
      await this.$http.get('/categories', { params: { isLeaf: false, isActive: true } }).then(response => {
        this.categories = response.data.data.filter(el => el.uuid !== this.$route.params.id)
      }).catch(err => { this.notify('Error', this.$t('notify.error'), err.message) })
      await this.$http.get('/brands').then(response => {
        this.brands = response.data.data
      }).catch(err => { this.notify('Error', this.$t('notify.error'), err.message) })
      this.isLoading = false
    },
    submitForm () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$http.patch(`/categories/edit/${this.category.uuid}`, {
            code: this.category.code,
            priority: this.category.priority,
            isLeaf: this.category.isLeaf,
            isActive: this.category.isActive,
            canPublished: this.category.canPublished,
            name_tm: this.category.name_tm,
            name_ru: this.category.name_ru,
            name_en: this.category.name_en,
            type: this.category.type,
            parentId: this.parent ? this.category.parentId : null,
            brands: this.category.category_brands.map(el => el.id),
            photo: this.imageUpdated ? this.category.image_isAdded : null
          }).then(() => {
            this.notify('Success', this.$t('notify.success'), `${this.$t('category')} ${this.$t('notify.sUpdated')}`)
            this.$router.back()
          }).catch(err => {
            this.notify('Error', this.$t('notify.error'), err.message)
          })
        } else {
          this.notify('Error', this.$t('notify.error'), this.$t('notify.formInvalid'))
        }
      })
    },
    updateCurrImg (input) {
      if (input.target.files && input.target.files[0]) {
        this.imageUpdated = true
        const reader = new FileReader()
        reader.onload = e => {
          this.category.image_isAdded = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    cancelForm () {
      this.$router.push(`/categories/${this.category.uuid}`)
    },
    notify (type, title, text) {
      this.$vs.notify({
        title,
        text,
        iconPack: 'feather',
        icon: type === 'Success' ? 'icon-check-circle' : 'icon-alert-circle',
        color: type === 'Success' ? 'primary' : 'danger'
      })
    }
  }
}
</script>
